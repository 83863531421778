<template lang="pug">
  section.slice-faq._w-full.sm__flex.tw-borders
    //- text
    section.sm__w-1x2._text-black._bg-white.pad
      //- header
      heading-arrowed(:caption="slice.primary.heading_caption")
        h2._inline {{ $prismic.asText(slice.primary.heading) }}

      //- items...
      section._mt-22.lg__mt-64._pb-22.lg__pb-80._relative
        details(v-for="item in slice.items")
          summary.sm__w-1x2.sm__pr-6._text-15.lg__text-18._cursor-pointer(@click="onDetailsClick")
            h6._pointer-events-none
              | {{ $prismic.asText(item.heading) }}
              //- icon-flashing-dot(size="small")
          rich-text._w-full.sm__w-1x2.sm__absolute._top-0._right-0._py-5.sm__py-0.sm__pl-6._text-10.lg__text-12.children-mt-lh.underline-links(:field="item.description")

    //- media
    figure.sm__w-1x2._relative
      resp-img(:bg="true", :image="slice.primary.image")
      //- min sizer
      ._pb-ar-1x1.md__pb-0(:class="{'md__min-h-appt-cell-xs': tickerVisible, 'md__min-h-appt-cell-sm': !tickerVisible}")

</template>

<script>
import { mapState } from 'vuex'
import HeadingArrowed from '@/components/HeadingArrowed'
import BtnRow from '@/components/BtnRow'
import RespImg from '@/components/RespImg'
import RichText from '@/components/RichText'
import IconFlashingDot from '@/components/IconFlashingDot'
export default {
  name: 'SliceFaq',
  props: {
    slice: Object
  },
  computed: {
    ...mapState(['tickerVisible'])
  },
  methods: {
    onDetailsClick (e) {
      const siblings = n => [...n.parentElement.children].filter(c => c !== n)
      siblings(e.target.parentNode).forEach(el => el.removeAttribute('open'))
    }
  },
  mounted () {
    this.$el.querySelector('details:first-child').setAttribute('open', true)
  },
  components: { HeadingArrowed, BtnRow, RespImg, RichText, IconFlashingDot }
}
</script>

<style lang="postcss">
.slice-faq{
  & details {
    /* & .icon-flashing-dot {
      visibility: hidden;
    } */
    &[open] {
      & summary {
        @apply underline;
      }
      /* & .icon-flashing-dot{
        visibility: visible;
      } */
    }
  }
}
</style>
