<!-- design only accomodates 1 frequency (eg "Every 3 months") but Bold supports adding multiple per product -->
<template lang="pug">
  .product-subscription-thumb._relative._text-12._cursor-pointer._group(@click="onBodyClick")
    //- (missing variant - editors note)
    template(v-if="!variant || !activeVariantSubscription")
      ._p-8._bg-yellow(v-if="$store.state.isLoggedIn")
        | <b>[Prismic Error] — {{ !variant ? 'Variant' : !activeVariantSubscription ? 'Subscription' : '' }} Not Found</b><br>
        | product: {{ product.title }} {{ product.variants.map(v => v.title) }}<br>
        | not-found: {{ slice.primary.variant_title }}

    //-
    template(v-else)
      //- cell sizer
      figure._pb-ar-8x9
        //- image
        resp-img(v-for="(item, i) in slice.items", :image="item.media", :bg="true", :lazy="false", v-show="i === currentMedia")

      //- text layer
      ._absolute._overlay._flex._flex-col._justify-between.pad.tw-borders._border._border-transparent.lg_hover__border-black(:class="{'_bg-gray-100': moreInfo}")
        //- upper
        //- selectors
        header
          product-subscription-checkbox._pointer-events-none(ref="checkbox", :sub="activeVariantSubscription", :product="product", :variant="variant", v-on="$listeners")
          //- description
          p._block._mt-3
            | Delivery {{ activeVariantSubscription.name }}
            | <br>{{ variant.title }} {{ product.productType }}

        //- (more info overlay)
        rich-text._flex-1._text-10._flex._w-full._items-center(:field="slice.primary.rollover_text", v-show="moreInfo", v-if="hasMoreInfo", @click.native.stop="moreInfo = false")

        //- (footer)
        ._flex._items-end._w-full._justify-between
          | {{ flavor }}
          //- more info btn
          button._block(@click.stop="moreInfo = !moreInfo", v-if="hasMoreInfo")
            svg-info-icon._h-12._-mr-2._-mb-3

      //- carousel arrows
      nav(v-if="slice.items.length > 1", v-show="!moreInfo")
        ._absolute._top-0._left-0._flex._items-center._h-full._pointer-events-none
          button.pad._pointer-events-auto(@click.stop="nextMedia(-1)")
            svg-chevron-down._transform._rotate-90._pointer-events-auto
        ._absolute._top-0._right-0._flex._items-center._h-full._pointer-events-none
          button.pad._pointer-events-auto(@click.stop="nextMedia(1)")
            svg-chevron-down._transform._-rotate-90._pointer-events-auto
</template>

<script>
import RespImg from '@/components/RespImg'
import RichText from '@/components/RichText'
import Icon from '@/components/Icon'
import SvgInfoIcon from '@/components/SvgInfoIcon'
import ProductSubscriptionCheckbox from './ProductSubscriptionCheckbox'
import SvgChevronDown from '@/components/SvgChevronDown'
export default {
  name: 'ProductSubscriptionThumb',
  props: {
    slice: Object
  },
  data () {
    return {
      subs: null,
      moreInfo: false,
      selected: [],
      currentMedia: 0
    }
  },
  computed: {
    product () {
      // get product from Shopify Products array for data consistency
      const handle = this.slice.primary.shopify_product?.handle
      return this.$store.state.shopifyProducts.find(prd => prd.handle === handle)
    },
    variant () {
      return this.product.variants.find(v => v.title === this.slice.primary.variant_title)
    },
    variantSubscriptions () {
      return this.subs?.find(sub => sub.variant === this.variant?.title)?.sellingPlans || []
    },
    activeVariantSubscription () {
      // first sub
      return this.variantSubscriptions[0]
    },
    flavor () {
      const flavor = this.$store.state.products.find(doc => doc.data.shopify_product_title === this.slice.primary.shopify_product?.title)?.data.flavor
      return flavor && this.$prismic.asText(flavor)
    },
    hasMoreInfo () {
      return this.slice.primary.rollover_text && this.$prismic.asText(this.slice.primary.rollover_text).length
    },
    isSelected () {
      const ids = this.variantSubscriptions.map(plan => `${plan.id}_${this.variant.id}`) || []
      return this.$store.state.cartSubs.find(item => item && ids.includes(item.id))
    }
  },
  methods: {
    async get () {
      if (this.product?.handle) {
        this.subs = this.subs || await this.$store.dispatch('getProductSubscriptions', this.product.handle)
        this.$emit('updated')
      }
    },
    onBodyClick () {
      return this.$refs.checkbox?.$el.click()
    },
    nextMedia (dir = 1) {
      // looped next/prev
      this.currentMedia = this.currentMedia + dir < 0 ? this.slice.items.length - 1
        : this.currentMedia + dir === this.slice.items.length ? 0
          : this.currentMedia + dir
    }
  },
  created () {
    this.get()
  },
  watch: {
    product () {
      this.get() // in case late
    }
  },
  components: { RichText, RespImg, SvgInfoIcon, Icon, ProductSubscriptionCheckbox, SvgChevronDown }
}
</script>

<style>
</style>
