<template lang="pug">
  article.appointments-page
    template(v-if="doc")
      //- upper with "get started sticky button"
      section
        slice-body(:slices="doc.data.body", :templates="templatesBody")

        //- (get started btn)
        router-link._sticky._block._bottom-0._left-0._w-full(v-if="!hasSubs", to="/appointments", @click.prevent="scrollToProducts")
          btn-row._w-full(infoLink="/appointments/faq") Get Started

      //- grid: select subscriptions from products_body
      section#products.lg__flex._items-start(v-if="hasSubs")
        header.lg__w-1x2._bg-white.lg__sticky.lg__sticky._top-hdr-xl.pad._flex._flex-col._justify-between(:class="{'_top-hdr-xl lg__min-h-appt-cell-sm': tickerVisible, '_top-hdr-lg lg__min-h-appt-cell-lg': !tickerVisible}")
          div
            heading-arrowed(v-if="productsHeading.length", :caption="doc.data.heading_caption")
              h2._inline {{ productsHeading }}
          ._block._w-full._mt-36
            router-link._text-15(to="/appointments/faq") > Read the FAQ
        .lg__w-1x2
          product-subscriptions-grid(:slices="doc.data.products_body")
</template>

<script>
import { mapState } from 'vuex'
import linkResolver from '@/prismic-link-resolver'
import SliceBody from '@/components/SliceBody'
import SliceTileHero from '@/slices/SliceTileHero'
import SliceHowItWorks from '@/slices/SliceHowItWorks'
import SliceFaq from '@/slices/SliceFaq'
// import SliceProductsSubscriptions from '@/slices/SliceProductsSubscriptions'
import BtnRow from '@/components/BtnRow'
import HeadingArrowed from '@/components/HeadingArrowed'
import ProductSubscriptionsGrid from '@/components/subscribe/ProductSubscriptionsGrid'
export default {
  name: 'AppointmentsPage',
  components: { SliceBody, BtnRow, HeadingArrowed, ProductSubscriptionsGrid },
  data () {
    return {
      doc: null,
      templatesBody: {
        'slice--tile__hero': SliceTileHero,
        'slice--how_it_works': SliceHowItWorks,
        'slice--faq': SliceFaq
      }
    }
  },
  computed: {
    ...mapState(['tickerVisible']),
    hasSubs () {
      return this.doc?.data.products_body?.length
    },
    productsHeading () {
      return !this.doc ? ''
        : this.$prismic.asText(this.doc.data.products_heading)
    }
  },
  methods: {
    async get () {
      const slug = this.$route.params.appointmentsPage
      this.doc = await this.$store.dispatch('getDoc', { type: 'appointment_page', uid: slug || 'appointments' })
      // set breadcrumb
      if (this.doc) {
        const crumbs = [['By Appointment', '/appointments']]
        if (slug) {
          crumbs.push([
            this.$prismic.asText(this.doc.data.title),
            linkResolver(this.doc)
          ])
        }
        this.$store.commit('setBreadcrumb', crumbs)
      }
    },
    scrollToProducts () {
      let offset = document.getElementById('app-header__top-row')?.offsetHeight || 0
      offset += document.getElementById('app-header__ticker')?.offsetHeight || 0
      this.$scrollTo('#products', { duration: 800, offset: offset * -1 })
    }
  },
  created () {
    this.get()
  }
}
</script>

<style>
</style>
