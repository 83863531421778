<template lang="pug">
  ul.product-subscriptions-listings._relative
    //- grid / mobile-carousel
    .sm__flex._flex-wrap._w-full._list-none(ref="carousel")
      //- slices...
      ._carousel-cell._w-full_-20.sm__w-1x2.lg__w-full.xl__w-1x2._overflow-hidden(v-for="slice in slices")
        product-subscription-thumb(:slice="slice", @updated="reflow", @selected="onSelected", @deselected="onDeselected")

    button._block._sticky._z-20._bottom-0._left-0._w-full._uppercase(:disabled="!selections.length", @click="addToCart")
      btn-row._w-full(infoLink="/appointments/faq")
        template(v-if="selections.length > 1") Add {{ selections.length }} Appointments to Bag
        template(v-else-if="selections.length === 1") Add Appointment to Bag
        template(v-else) Select an Appointment

    //- carousel watcher
    ._w-px.sm__hidden(v-visibility, @visible="initMobileCarousel", @hidden="destoryCarousel")

</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'flickity'
import ProductSubscriptionThumb from './ProductSubscriptionThumb'
import BtnRow from '@/components/BtnRow'
import SvgChevronDown from '@/components/SvgChevronDown'
export default {
  name: 'ProductSubscriptionsGrid',
  props: {
    slices: Array
  },
  data () {
    return {
      flkty: null,
      selections: []
    }
  },
  computed: {
    ...mapState(['cartSubs'])
  },
  methods: {
    initMobileCarousel () {
      this.flkty = new Flickity(this.$refs.carousel, {
        // cellAlign: 'left',
        draggable: '>1',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 0
        // autoPlay: 4000, // duration
        // pauseAutoPlayOnHover: false
      })
      // go prev / next to show looped previous thumb?
      this.flkty.previous(true)
      this.flkty.next(true)
    },
    reflow () {
      return this.flkty?.reloadCells()
    },
    addToCart () {
      if (this.selections.length) {
        this.$store.dispatch('addSubscriptionsToCart', this.selections)
        this.selections = []
        // uncheck all thumbs
        this.$el.querySelectorAll('input[type="checkbox"]').forEach(el => { el.checked = false })
      }
    },
    destoryCarousel () {
      if (this.flkty) {
        this.flkty.destroy()
        this.flkty = null
      }
    },
    onSelected (item) {
      this.selections.push(item)
      console.log(this.selections)
    },
    onDeselected (target) {
      const i = this.selections.findIndex(item => item.id === target.id)
      if (i > -1) {
        this.selections.splice(i, 1)
      }
      console.log(this.selections)
    }
  },
  created () {
    this.$store.dispatch('getProducts')
  },
  mounted () {
    // carousel init via watcher
  },
  components: { SvgChevronDown, ProductSubscriptionThumb, BtnRow }
}
</script>

<style>
</style>
