<template lang="pug">
  tile.slice-tile-hero(v-bind="$attrs")
</template>

<script>
import Tile from '@/components/Tile'
export default {
  name: 'SliceTileHero',
  inheritAttrs: false,
  components: { Tile }
}
</script>
