<template lang="pug">
  section.slice-how-it-works._w-full.md__flex._items-start._relative
    //- media scroll
    ._relative.md__w-1x2
      //- medias...
      figure._relative._pb-ar-1x1.md__pb-0(v-for="(field, i) in ['image', 'image2']", v-if="slice.primary[field] && slice.primary[field].url", :class="[minH, {'hidden md__block': i}]")
        //- (image)
        template(v-if="slice.primary[field].kind === 'image'")
          resp-img(:bg="true", :image="slice.primary[field]")
        //- (video)
        template(v-else)
          video._absolute._overlay._object-cover._object-center(:src="slice.primary[field].url", autoplay, muted, loop, playsinline)

    //- text column
    section._sticky._right-0.md__w-1x2._text-black._bg-gray-100.pad._flex._flex-col._justify-between(:class="[minH, stickyTop]")
      //- header
      heading-arrowed._mb-22
        h2._inline {{ $prismic.asText(slice.primary.heading) }}

      //- steps
      ol._mb-22._list-none._grid._grid-cols-1.lg__grid-cols-3._text-10.md__text-12._-mx-3
        //- items...
        li._px-3(v-for="(item, i) in slice.items", :class="{'_mt-20 lg__mt-0': i}")
          div
            img._block._h-7._mb-8(:src="item.icon.url", :alt="item.icon.alt")
          rich-text(:field="item.text")

      //- footer text
      rich-text._text-10.md__text-12(:field="slice.primary.footer_text")
</template>

<script>
import { mapState } from 'vuex'
import RespImg from '@/components/RespImg'
import HeadingArrowed from '@/components/HeadingArrowed'
import RichText from '@/components/RichText'
export default {
  name: 'SliceHowItWorks',
  components: { RichText, RespImg, HeadingArrowed },
  props: {
    slice: Object
  },
  computed: {
    ...mapState(['tickerVisible']),
    minH () {
      return this.tickerVisible ? 'md__min-h-appt-cell-xs' : 'md__min-h-appt-cell-md'
    },
    stickyTop () {
      return this.tickerVisible ? '_top-hdr-xl' : '_top-hdr-lg'
    }
  }
}
</script>

<style>
.slice-how-it-works {
  & li h6 {
    font-size: calc(15/12 * 1em);
    @apply mb-7;
  }
}
</style>
