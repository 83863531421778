<template lang="pug">
  label._flex._items-center._cursor-pointer._leading-none
    ._flex-none._mr-5
      input._text-20(ref="checkbox", type="checkbox", v-model="selected", @change="onChange")
    h6._flex-1._text-15
      | {{ product.title }}
      //- | {{ product.title }} ({{variant.title}} {{ sub.name }}) {{ discount }}
      //- | {{ sub.name }} {{ discount }}
    ._ml-4._line-through._text-15(v-if="sub.compare_at_price > sub.price") {{ price(sub.compare_at_price / 100) }}
    //- price
    ._ml-4._text-15 {{ price(sub.price / 100) }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatSubscriptionCartItem } from '@/store'
export default {
  name: 'ProductSubscriptionCheckbox',
  props: {
    sub: Object,
    product: Object,
    variant: Object
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    ...mapState(['cartSubs']),
    ...mapGetters(['price']),
    cartItemBody () {
      const sellingPlan = { ...this.sub }
      return formatSubscriptionCartItem(sellingPlan, this.variant, this.product)
    },
    itemQty () {
      const title = this.variant.title
      const no = !isNaN(title.split('×')[0]) ? title.split('×')[0]
        : !isNaN(title.split('x')[0]) ? title.split('x')[0]
          : 1
      return no
    },
    discount () {
      const discount = (this.sub.compare_at_price - this.sub.price) / this.sub.compare_at_price * 100
      return discount && ` – Save ${discount}%`
    }
  },
  methods: {
    onChange (e) {
      // console.log(e.target.checked, this.selected)
      if (this.selected) {
        // add!
        this.$emit('selected', { ...this.cartItemBody })
        // this.$store.dispatch('addSubscriptionsToCart', [{ ...this.cartItemBody }])
      } else {
        // remove!
        this.$emit('deselected', { ...this.cartItemBody })
        // this.$store.dispatch('updateSubscriptionInCart', { id: this.cartItemBody.id, quantity: 0 })
      }
    },
    // setIsSelectedFromCart () {
    //   this.selected = this.cartSubs.find(item => item.id === this.cartItemBody.id) !== undefined
    // },
    deselect () {
      this.selected = false
    }
  }
  // created () {
  //   this.setIsSelectedFromCart()
  // },
  // watch: {
  //   cartSubs () {
  //     this.setIsSelectedFromCart()
  //   }
  // }
}
</script>

<style>
</style>
